import axios, {InternalAxiosRequestConfig, AxiosResponse} from "axios";
import {message} from 'ant-design-vue';
import md5 from '../md5.js'


let baseUrl;
// const DEV_BASE_API = "http://192.168.20.22:1553"
const DEV_BASE_API = "http://111.22.162.220:1553"  // dev
const TEST_API = "http://111.22.162.220:1553"      // build test
const PROD_BASE_API = "http://www.dawei.art"        // build production
export const Version = 2

if (process.env.NODE_ENV === 'development') {
  baseUrl = DEV_BASE_API
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_TITLE === 'test') {
    baseUrl = TEST_API
  } else if (process.env.VUE_APP_TITLE === 'build') {
    baseUrl = PROD_BASE_API
  }
}


const CONFIG_HEADER = {
  "Accept": 'application/json',
  'Content-Type': 'application/json',
}


const service = axios.create({
  timeout: 0,
  withCredentials: false,
  headers: CONFIG_HEADER,
  baseURL: baseUrl,
})


const needNtTokenList: string[] = []

function removeNullPro(obj) {
  let newObj = {};
  if (typeof obj === 'string') {
    obj = JSON.parse(obj)
  }
  if (obj instanceof Array) {
    newObj = []
  }
  if (obj instanceof Object) {
    Object.keys(obj).forEach(function (k) {
      var value = obj[k];
      if (obj.hasOwnProperty(k) && value !== '' && value !== null && value !== undefined && value !== 'null') {
        if (value instanceof Array || value instanceof Object) {
          newObj[k] = JSON.stringify(value);
        } else {
          newObj[k] = value;
        }
      }
    });
  }
  return newObj;
}


service.interceptors.request.use((config: InternalAxiosRequestConfig) => {


  // if (config.headers.order_token) {
  //   config.headers = {
  //     ...CONFIG_HEADER,
  //     order_token: config.headers.order_token
  //   }
  // }
  // if (config.headers.responseType && config.headers.responseType === 'blob') {
  //   config.responseType = config.headers.responseType
  //   config.headers = {
  //     ...CONFIG_HEADER,
  //   }
  // }
  if (config.headers.boxIp) {
    // @ts-ignore
    config.headers = {
      ...CONFIG_HEADER,
      boxIp: config.headers.boxIp,
    }
  }
  config.headers.Authorization = localStorage.getItem('dw__t') ?? undefined;
  config.headers.Version = Version
  let millisecond = new Date().getTime();
  config.headers.Timestamp = millisecond;
  if (config.method === 'post' || config.method === 'POST') {
    let _sig = [] as Array<any>;
    let sortData = removeNullPro(config.data);
    if (sortData instanceof Array) {
      console.log()
      let Sign = millisecond + '&' + JSON.stringify(config.data) + '&012AsHa6%)a12ssc2022_';
      // console.log('签名前POST:' + Sign);
      // console.log('签名后POST:' + md5(Sign));
      config.headers.Sign = md5(Sign)
    } else {
      Object.keys(sortData).forEach(v => {
        _sig.push(`${v}=${sortData[v]}`)
      });
      let Sign = millisecond + '&' + _sig.sort().join('&') + '&012AsHa6%)a12ssc2022_';
      // console.log('签名前POST:' + Sign);
      // console.log('签名后POST:' + md5(Sign));
      config.headers.Sign = md5(Sign)
    }
    // request.data = {
    //   ...request.data,
    //
    // }
    // post请求要序列化
    // let data = qs.parse(request.data);
    //
    // request.data = qs.stringify({
    //   ...data
    // });
  } else if (config.method === 'get' || config.method === 'GET') {
    if (config.params) {
      let _sig = [];
      let _data = removeNullPro(config.params);
      Object.keys(_data).forEach(v => {
        // @ts-ignore
        _sig.push(`${v}=${_data[v]}`)
      });
      let Sign = millisecond + '&' + _sig.sort().join('&') + '&012AsHa6%)a12ssc2022_'
      config.headers.Sign = md5(Sign)
      // console.log('签名前GET:' + Sign)
      // console.log('签名后GET:' + md5(Sign))
    } else {
      // @ts-ignore
      let list = config.url.split('?')
      if (list.length > 1) {
        let Sign = millisecond + '&' + list[list.length - 1] + '&012AsHa6%)a12ssc2022_'
        config.headers.Sign = md5(Sign)
        // console.log('签名前GET:' + Sign)
        // console.log('签名后GET:' + md5(Sign))
      } else {
        let Sign = millisecond + '&012AsHa6%)a12ssc2022_'
        config.headers.Sign = md5(Sign)
        // console.log('签名前GET:' + Sign)
        // console.log('签名后GET:' + md5(Sign))
      }

    }
  }
  return config;
}, async (err: any) => {
  await Promise.reject(err)
})


service.interceptors.response.use((response: AxiosResponse<any, any>) => {
  if (response.status === 200) {
    console.log(response);
    if (response.data.code == 405) {
      message.destroy()
      message.warn('凭证已失效，请重新登录', 2)
      window.location.replace('/#/login')
    } else if (response.data.message) {
      message.error(response.data.message)
      console.log(`%c${response.data.message}`, 'color: #FF9900;font-size: 24px;font-weight: bold;text-decoration: underline;');
      return response.data.data
    } else {
      return response.data.data;
    }
  }
}, (err: any) => {
  message.destroy()
})


export default service;

